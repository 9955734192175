@font-face {
  font-family: 'AIS-font';
  src: url('../src/assets/fonts/ais.ttf') format('truetype');
}

body .gomo-widgets {
  margin: 0;
  font-family: 'AIS-font' !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: rgba(0, 0, 0, 0.85) !important;
  position: fixed !important;
  z-index: 99999 !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  scrollbar-color: transparent transparent !important;
}

body .gomo-widgets ::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
  height: 0 !important;
}

body .form-internet-problem {
  margin: 0;
  font-family: 'AIS-font' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

body .form-kyc {
  margin: 0;
  font-family: 'AIS-font' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

body .form-survey {
  margin: 0;
  font-family: 'AIS-font' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

*:focus .gomo-widgets,
.form-survey,
.form-internet-problem,
.form-kyc {
  outline: none !important;
}

.form-survey textarea.ant-input {
  min-height: 40px !important;
}

h1,
h2,
h3,
h4 .gomo-widgets,
.form-survey,
.form-internet-problem {
  font-style: normal;
  font-weight: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  padding: 0;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
}

span,
option,
li .gomo-widgets,
.form-survey,
.form-internet-problem {
  font-style: normal;
  font-weight: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
}

input .gomo-widgets {
  color: rgba(0, 0, 0, 0.85) !important;
  height: auto !important;
}

h1 .gomo-widgets {
  padding: 0;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
  line-height: normal;
}
h2 .gomo-widgets {
  padding: 0;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
  line-height: normal;
}
h3 .gomo-widgets {
  padding: 0;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
  line-height: normal;
}
h4 .gomo-widgets {
  padding: 0;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
}
span .gomo-widgets {
  padding: 0;
  margin: 0;
  line-height: normal;
}
.gomo-widgets,
a:active,
a:hover {
  text-decoration: underline !important;
}

@media only screen and (max-width: 480px) {
  h2 .gomo-widgets {
    font-size: 28px;
  }
  h3 .gomo-widgets {
    font-size: 23px;
  }
  h4 .gomo-widgets {
    font-size: 20px;
  }
}
@media only screen and (max-width: 360px) {
  h2 .gomo-widgets {
    font-size: 25px;
  }
  h3 .gomo-widgets {
    font-size: 21px;
  }
  h4 .gomo-widgets {
    font-size: 18px;
  }
}

.form-survey .survey-img {
  max-height: 105px;
  max-width: 105px;
}

@media only screen and (max-width: 512px) {
  .form-survey .survey-img {
    max-height: 85px;
    max-width: 85px;
  }
}

.gomo-widgets .livechat .ant-input {
  font-family: 'AIS-font' !important;
  font-size: 19px !important;
  line-height: 1.5715;
  background-color: #f3f3f3 !important;
  border: none !important;
  border-radius: 0.5em !important;
  color: rgba(0, 0, 0, 0.85) !important;
  height: auto !important;
}

.ant-input {
  font-family: 'AIS-font' !important;
  font-size: 19px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.gomo-widgets .livechat .ant-btn {
  width: 50px !important;
}

.gomo-widgets .livechat .ant-form-item {
  margin-right: 0px !important;
}

.gomo-widgets .modal-gomo {
  display: contents;
}

.gomo-widgets .modal-gomo .ant-input-lg {
  font-size: 24px !important;
  font-family: 'AIS-font' !important;
  color: rgba(0, 0, 0, 0.85) !important;
  height: auto !important;
}

.gomo-widgets .modal-gomo .ant-btn-primary {
  font-size: 25px !important;
  width: auto !important;
  min-width: auto !important;
  font-weight: bold !important;
  font-family: 'AIS-font' !important;
  border-color: #e4238f !important;
  background: #e4238f !important;
  height: 1.9em !important;
  border-top-right-radius: 0.5em !important;
  border-bottom-right-radius: 0.5em !important;
}

.gomo-widgets .modal-gomo .ant-input-affix-wrapper {
  border-top-left-radius: 0.8em !important;
  border-bottom-left-radius: 0.8em !important;
  height: 3em !important;
  border: 1px solid #e4238f !important;
}

.gomo-widgets .ant-input-suffix {
  display: none !important;
}

@-moz-document url-prefix() {
  .gomo-widgets .bottom-flex {
    display: inline-table;
    flex: 1;
    height: 6.5em;
    width: 100%;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .gomo-widgets .ie-inline {
      display: inline-block !important;
      width: 100% !important;
    }
    .gomo-widgets .ie-inline-dialog {
      max-width: 77% !important;
    }
    .gomo-widgets .ie-inline-dialog-choice {
      max-width: 92% !important;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .gomo-widgets .button-ie {
    justify-content: flex-start !important;
  }
  .gomo-widgets .bottom-flex {
    display: inline-table;
    flex: 1;
    height: 5.5em;
    width: 100%;
  }
  .gomo-widgets .ie-inline {
    display: inline-block !important;
    width: 100% !important;
  }
  .gomo-widgets .ie-inline-dialog {
    max-width: 77% !important;
  }
  .gomo-widgets .ie-inline-dialog-choice {
    width: 92% !important;
  }

  .gomo-widgets .ie-modal-gomo {
    left: 40%;
  }
  .gomo-widgets .ie-image {
    display: inline-block !important;
    width: 100% !important;
  }
  .gomo-widgets .ie-image-width {
    width: auto !important;
  }
  .gomo-widgets .ie-wrap-image {
    display: inline-block !important;
    height: auto !important;
  }

  *::-ms-backdrop,
  .gomo-widgets .box {
    display: inline-block;
    height: auto;
    word-break: break-all;
    -ms-word-break: break-all;
  }
  *::-ms-fullscreen,
  .gomo-widgets .box {
    display: inline-block;
    height: auto;
    word-break: break-all;
    -ms-word-break: break-all;
  }
}

.gomo-widgets .scroll-wrap-widget ::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
  height: 0 !important;
}

.gomo-widgets .scroll-wrap-widget {
  overflow-y: scroll !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  scrollbar-color: transparent transparent !important;
}

.gomo-widgets .scroll-wrap-widget-suggestion {
  scrollbar-width: thin !important;
  scrollbar-color: gray transparent !important;
}

.gomo-widgets .scroll-wrap-widget-suggestion ::-webkit-scrollbar {
  height: 10px !important;
  display: flex;
}

.gomo-widgets .scroll-wrap-widget-suggestion ::-webkit-scrollbar-track {
  background: transparent !important;
}

.gomo-widgets .scroll-wrap-widget-suggestion ::-webkit-scrollbar-thumb {
  background: grey !important;
  border-radius: 10px !important;
}

.gomo-widgets :-ms-input-placeholder {
  color: #bfbfbf !important;
}

.gomo-widgets ::placeholder {
  color: #bfbfbf !important;
  opacity: 1;
}

.gomo-widgets ::-ms-input-placeholder {
  color: #bfbfbf !important;
}

.gomo-overflow-hiden {
  overflow: hidden;
}

.form-internet-problem .ant-input {
  min-height: 40px !important;
  font-size: 21px !important;
  border: 1.5px solid #e7279b !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
}

.form-internet-problem .ant-input:focus {
  border-color: #403b89 !important;
  border-right-width: 1px !important;
}

.form-internet-problem .ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 21px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.form-internet-problem .ant-select-selector {
  min-height: 40px !important;
  font-size: 21px !important;
  border: 1.5px solid #e7279b !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  background-color: #e4238f !important;
  align-items: center !important;
  color: white !important;
}

.form-internet-problem .ant-select-selection-placeholder {
  color: #f0f0f0;
}

.form-internet-problem .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #e7279b !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgb(231, 39, 155, 0.2) !important;
}

.form-internet-problem .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: lightgray !important;
  border: 1.5px solid lightgray !important;
}

.form-internet-problem .ant-picker {
  min-height: 40px !important;
  font-size: 21px !important;
  border: 1.5px solid #e7279b !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  width: 100%;
}

.form-internet-problem .ant-picker-input > input {
  font-size: 21px !important;
  font-family: 'AIS-font' !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

button {
  display: inline !important;
  min-width: auto !important;
}

.ant-picker-panel-container {
  font-family: 'AIS-font' !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 20px !important;
}

.ant-btn-sm {
  height: auto !important;
  font-size: 20px !important;
}

.form-internet-problem .ant-btn {
  min-height: 40px !important;
  font-size: 21px !important;
  font-weight: bold !important;
  align-items: center !important;
  width: 100% !important;
}

.form-internet-problem .ant-btn :hover {
  border-radius: none !important;
  opacity: 0.5 !important;
}

.form-internet-problem .ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 3px;
  right: 11px;
  bottom: 0;
  left: 11px;
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-picker-panel-container {
  z-index: 99999 !important;
}

.ant-modal-content {
  border-radius: 20px;
  font-family: 'AIS-font' !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: red !important;
  font-size: 18px !important;
  font-family: 'AIS-font' !important;
  font-weight: 100 !important;
}

.ant-form-item-explain-error {
  font-weight: 100 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  font-size: 21px !important;
  font-family: 'AIS-font' !important;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none !important;
  opacity: 0.7 !important;
  border: none !important;
}

@media only screen and (max-width: 480px) {
  .ant-picker-dropdown {
    width: 320px !important;
  }

  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel {
    width: 230px !important;
  }

  .ant-picker-date-panel .ant-picker-content {
    width: 200px !important;
  }

  .ant-picker-date-panel .ant-picker-content th {
    width: 20px !important;
  }

  .ant-picker-time-panel-column {
    width: 45px !important;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 98% !important;
  height: 100% !important;
}
.ant-picker-input {
  padding: 0px 20px !important;
}

.ant-picker {
  padding: 0 !important;
}

.ant-checkbox + span {
  font-size: 18px !important;
}

.ant-picker-input > input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: #766f70 !important;
  font-size: 20px !important;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-radius: 1em;
  transition: all 0.3s;
}

.form-kyc .ant-input[disabled] {
  background-color: #f5f5f5 !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f5 !important;
}

.form-kyc .ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px 20px !important;
  color: #766f70 !important;
  font-size: 20px !important;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 1em;
  transition: all 0.3s;
}

.form-kyc .ant-form-item-explain.ant-form-item-explain-error {
  color: red !important;
  font-weight: normal !important;
  font-size: 18px !important;
  min-height: 0px !important;
  font-family: 'AIS-font' !important;
}

.form-kyc .ant-select-selector {
  min-height: 1.7em !important;
  font-size: 20px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 1em !important;
  padding: 0px 20px !important;
  background-color: #fff !important;
  align-items: center !important;
  color: #766f70 !important;
  font-family: 'AIS-font' !important;
}

/* .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #B96896 !important;
}

.ant-form-item-has-error :not(.ant-select-disabled):not(.ant-select-borderless).ant-select, .ant-form-item-has-error :not(.ant-select-affix-wrapper-disabled):not(.ant-select-affix-wrapper-borderless).ant-select-affix-wrapper, .ant-form-item-has-error :not(.ant-select-disabled):not(.ant-select-borderless).ant-select:hover, .ant-form-item-has-error :not(.ant-select-affix-wrapper-disabled):not(.ant-select-affix-wrapper-borderless).ant-select-affix-wrapper:hover {
  background-color: #fff;
  border-color: #B96896 !important;
} */

.ant-popover-inner-content {
  padding: 10px !important;
  border-radius: 20px;
}

.form-kyc-upload .ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 0 !important;
}

.form-kyc .ant-select-arrow {
  right: 15px;
}

.form-kyc .ant-btn:not([disabled]):hover {
  text-decoration: none !important;
  opacity: 0.7 !important;
  border: 1px solid #b45089 !important;
}

.form-kyc .ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: lightgray !important;
  text-shadow: none;
  box-shadow: none;
}

.form-kyc
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  border-color: #b45089 !important;
}

.form-kyc
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector:hover,
.ant-picker-status-error.ant-picker:hover,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: #ff4d4f !important;
}

.form-kyc .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-picker-status-error.ant-picker {
  border-color: #b45089 !important;
}
.form-kyc .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b45089 !important;
  border-color: #b45089 !important;
}

.form-kyc .ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.3);
}

.form-kyc-modal .ant-modal-content {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  border-radius: 15px;
  border: 2px solid #b45089;
}

.form-kyc-modal .ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.form-kyc .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #b45089;
}

.spinner-box {
  width: 15px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  background-color: transparent;
}

.pulse-container {
  width: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 2.5px;
  height: 2.5px;
  border-radius: 50%;
  background-color: #fff;
}

.pulse-bubble-1 {
  opacity: 0.5;
  animation: pulse 1.5s ease infinite;
}
.pulse-bubble-2 {
  animation: pulse 1.5s ease infinite;
  animation-delay: 0.5s;
}
.pulse-bubble-3 {
  animation: pulse 1.5s ease infinite;
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

form .ant-form-item-margin-offset {
  margin-bottom: unset !important;
}
.ant-form-item-with-help {
  margin-bottom: unset !important;
}
